import Axios from 'axios'
import { extractOnlyNumbers, formatDate } from '@/core/utils/formatter'

export default {
  search: (idFuncionario = null, idCidade = null) =>
    Axios.get('/clinicas', {
      params: {
        funcionario: idFuncionario,
        cidade: idCidade,
      },
    }),
  getAgendamentos: token => {
    return Axios.get(`/clinicas/${encodeURIComponent(token)}/agendamentos`)
  },
  finalizarAso: (token, idAso, formData) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/finalizar`, formData)
  },
  finalizarAsoHibrido: (token, idAso, formData) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/finalizar-hibrido`, formData)
  },
  salvarAnamnese: (token, idAso, anamnese) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/salvar-anamnese`, {
      anamnese,
    })
  },
  finalizarAsoHai: (token, idAso, formData) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/finalizar-hai`, formData)
  },
  getResultadosComplementares: (token, idAso) => {
    return Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/resultados-complementares`)
  },
  definirHorario: ({ id_aso, dt_agendamento, orientacoes }, token) =>
    Axios.patch(`/clinicas/${encodeURIComponent(token)}/asos/${id_aso}/definir-horario`, {
      dt_agendamento: formatDate(dt_agendamento),
      orientacoes,
    }),
  openFichaClinica: (token, idAso) =>
    Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/ficha-clinica/download`),
  openAso: (token, idAso) => Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/arquivo-retorno/download`),
  findByCnpj: cnpj => Axios.get(`/admin/clinicas/cnpj/${extractOnlyNumbers(cnpj)}`),
  getAnamnese: (token, idAso) => Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/anamnese`),
}
